<template>
  <v-card
    fill-height
    max-width="480"
    class="media-creator mx-auto pt-2 rounded-lg text-center"
  >
    <v-card-title class="accent--text font-weight-black justify-center">
      Crie sua mensagem!
    </v-card-title>
    <v-scroll-y-transition>
      <v-img 
        v-if="step>1"
        :src="url+templates[fields.template.value].frames[0].url"
        contain
        max-width="288px"
        class="preview mx-auto ma-2 mb-6"
        :class="['template-'+templates[fields.template.value].id, {'rounded-lg': render.border}]"
      >
        <div
          v-if="fields.user_photo.value!=null"
          class="user_photo content rounded-circle"
          :style="{ 'background-image': 'url('+fields.user_photo.value+')'}"
        />
        <div
          v-if="fields.recipient_photo.value!=null"
          class="recipient_photo content rounded-circle"
          :style="{ 'background-image': 'url('+fields.recipient_photo.value+')'}"
        />
        <span class="user_name content text-caption">
          <!-- <em>DE:</em> -->
          <br v-if="fields.template.value==2">{{ fields.user_name.value }}
        </span>
        <span class="recipient_name content text-caption">
          <!-- <em>PARA:</em> -->
          <br v-if="fields.template.value==2">{{ fields.recipient_name.value }}
        </span>
        <span class="message content text-body-2 d-flex align-center justify-center">
          {{ fields.message.value!=null ? fields.message.value : '#orgulho' }}
        </span>
        <img 
          v-if="fields.template.value>0"
          src="/campanha/enxamedeamor/template-ballon.png"
          class="ballon"
        >
      </v-img>
    </v-scroll-y-transition>

    <v-stepper 
      v-model="step"
      color="accent"
      class="elevation-0"
    >
      <v-stepper-items
        class="pa-4 pa-sm-8 py-0 pt-sm-0"
      >
        <v-stepper-content 
          :step="1"
          class="pa-0"
        >
          <!-- <h3 class="text-h6 accent--text font-weight-bold mb-4">
            {{ steps[step-1].title }}
          </h3> -->
          <p class="text-body-1 mb-6">
            {{ steps[step-1].text }}
          </p>
          <v-hover
            v-for="(t, j) in templates"
            :key="'template-'+j"
            v-slot="{ hover }"
          >
            <v-img 
              :src="url+t.cover"
              contain
              max-width="288px"
              class="template mx-auto rounded-lg my-8"
              :class="{ 'elevation-8': hover }"
              style="cursor: pointer;"
              @click="selectTemplate(j)"
            />
          </v-hover>
        </v-stepper-content>
        <v-stepper-content 
          :step="2"
          class="pa-0"
        >
          <!-- <h3 class="text-h5 accent--text font-weight-bold mb-4">
            {{ steps[step-1].title }}
          </h3> -->
          <v-card-text 
            class="mx-auto py-0"
            style="max-width: 360px;"
          >
            <p class="text-body-1 mb-8">
              {{ steps[step-1].text }}
            </p>
            <div class="d-flex justify-center">
              <image-uploader
                id="user_upload"
                class-name="file-input"
                :preview="false"
                :debug="1"
                :auto-rotate="true"
                output-format="verbose"
                @input="setPhoto"
              >
                <label 
                  for="user_upload" 
                  slot="upload-label"
                  class="upload-label mr-4"
                  style="cursor: pointer;"
                  @click="uploadPhoto('user_photo')"
                >
                  <v-icon 
                    v-if="fields.user_photo.value==null"
                    color="primary"
                    size="56"
                    class="pa-4 rounded-circle"
                    style="border: 1px solid;"
                  >
                    {{ icons.photo }}
                  </v-icon>
                  <v-avatar
                    v-else
                    size="56"
                    class=""
                  >
                    <v-img
                      :src="fields.user_photo.value"
                      cover
                      alt="Clique aqui para alterar a imagem."
                    />
                  </v-avatar>
                </label>
              </image-uploader>
              <v-text-field
                v-model="fields.user_name.value"
                :loading="fields.user_name.loading"
                :disabled="fields.user_name.disabled"
                :hint="fields.user_name.hint"
                :maxlength="fields.user_name.max"
                :counter="fields.user_name.max"
                prefix="DE:"
                label="Digite seu nome"
                required
                autocomplete="off"
                outlined
                class="control"
              />
            </div>
            <div class="d-flex">
              <image-uploader
                id="recipient_upload"
                class-name="file-input"
                :preview="false"
                :debug="1"
                :auto-rotate="true"
                output-format="verbose"
                @input="setPhoto"
              >
                <label 
                  for="recipient_upload" 
                  slot="upload-label"
                  class="upload-label mr-4"
                  style="cursor: pointer;"
                  @click="uploadPhoto('recipient_photo')"
                >
                  <v-icon 
                    v-if="fields.recipient_photo.value==null"
                    color="primary"
                    size="56"
                    class="pa-4 rounded-circle"
                    style="border: 1px solid;"
                  >
                    {{ icons.photo }}
                  </v-icon>
                  <v-avatar
                    v-else
                    size="56"
                    class=""
                  >
                    <v-img
                      :src="fields.recipient_photo.value"
                      cover
                      alt="Clique aqui para alterar a imagem."
                    />
                  </v-avatar>
                </label>
              </image-uploader>
              <v-text-field
                v-model="fields.recipient_name.value"
                :loading="fields.recipient_name.loading"
                :disabled="fields.recipient_name.disabled"
                :maxlength="fields.recipient_name.max"
                :counter="fields.recipient_name.max"
                :hint="fields.recipient_name.hint"
                prefix="PARA:"
                label="Nome dx destinatárix"
                required
                autocomplete="off"
                outlined
                class="control"
              />
            </div>
          </v-card-text>
        </v-stepper-content>
        <v-stepper-content 
          :step="3"
          class="pa-0"
        >
          <!-- <h3 class="text-h5 accent--text font-weight-bold mb-4">
            {{ steps[step-1].title }}
          </h3> -->
          <v-card-text 
            class="mx-auto py-0"
            style="max-width: 360px;"
          >
            <p class="text-body-1 mb-8">
              {{ steps[step-1].text }}
            </p>
            <v-textarea
              v-model="fields.message.value"
              label="Mensagem"
              placeholder="#orgulho"
              :maxlength="fields.message.max"
              :hint="fields.message.hint"
              counter
              outlined
            ></v-textarea>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-content 
          :step="4"
          class="pa-0"
        >
          <!-- <h3 class="text-h5 accent--text font-weight-bold mb-4">
            {{ steps[step-1].title }}
          </h3> -->
          <v-card-text 
            class="mx-auto py-0"
            style="max-width: 360px;"
          >
            <p class="text-body-1 mb-8">
              {{ steps[step-1].text }}
            </p>
            <v-autocomplete
              v-model="fields.geofence.value"
              :items="fields.geofence.items"
              :loading="fields.geofence.loading"
              :disabled="fields.geofence.disabled"
              item-text="title"
              return-object
              hide-no-data
              outlined
              chips
              label="Selecione o bairro"
              class="geofence"
            />
          </v-card-text>
        </v-stepper-content>

        <v-stepper-content 
          :step="5"
          class="pa-0"
        >
          <!-- <h3 class="text-h5 accent--text font-weight-bold mb-4">
            {{ steps[step-1].title }}
          </h3> -->
          <v-card-text 
            class="mx-auto py-0"
            style="max-width: 360px;"
          >
            <p class="text-body-1 mb-4">
              {{ steps[step-1].text }}
            </p>
            <v-text-field
              v-model="fields.fullname.value"
              :loading="fields.fullname.loading"
              :disabled="fields.fullname.disabled"
              hide-details
              label="Nome completo"
              name="nome"
              required
              outlined
              class="control mb-6"
            />
            <v-text-field
              v-model="fields.email.value"
              :loading="fields.email.loading"
              :disabled="fields.email.disabled"
              :hint="fields.email.hint"
              hide-details
              label="Email"
              type="email"
              name="email"
              required
              outlined
              class="control mb-1"
            />

            <v-checkbox
              v-model="fields.policy.value"
              hide-details
              class="mt-4"
            >
              <template v-slot:label>
                Li e aceito a
                <a
                  href="https://mobees.com.br/campanhas/politica-de-privacidade-enxame-de-amor"
                  target="_blank"
                  class="ml-1"
                  @click.stop
                >política de privacidade</a>.
              </template>
            </v-checkbox>

            <v-checkbox
              v-model="fields.subscription.value"
              hide-details
              class="mt-2"
            >
              <template v-slot:label>
                Desejo receber cupons e ofertas.
              </template>
            </v-checkbox>
          </v-card-text>
        </v-stepper-content>
        <v-stepper-content 
          :step="6"
          class="pa-0"
        >
          <h3 class="text-h5 accent--text font-weight-bold mb-4">
            {{ steps[step-1].title }}
          </h3>
          <p class="text-body-1 mb-4">
            {{ steps[step-1].text }}
          </p>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-card-actions 
      v-show="step>1"
      class="pa-8 py-4"
    >
      <v-btn
        v-show="step>1&&step!=6"
        text
        color="grey"
        class="px-4"
        @click="nextStep(false)"
      >
        Voltar
      </v-btn>

      <v-spacer />

      <v-btn 
        v-show="step>1"
        depressed
        color="primary"
        :loading="loading"
        :disabled="!steps[step-1].complete"
        class="px-4"
        @click="nextStep"
      >
        {{ step==5 ? 'Enviar' : step==6 ? 'Enviar nova mensagem' : 'Próximo' }}
      </v-btn>

      <v-spacer v-if="step==6" />
    </v-card-actions>
    <p 
      v-if="step==5"
      class="recaptcha-text text-caption text--disabled pa-4"
    >
      Este site é protegido por reCAPTCHA, aplicando-se a
      <a href="https://policies.google.com/privacy" target="_blank">Política de Privacidade</a> e os
      <a href="https://policies.google.com/terms" target="_blank">Termos de serviço</a> do Google.
    </p>
  </v-card>
</template>

<style>

  html, body, #app.v-application {
    background: transparent !important;
    overflow-y: hidden;
  }


  #app.login-content {
    background: transparent !important;
    padding: 4px;
  }
  @media screen and (max-device-width: 960px) {
    #app.login-content {
      padding: 0;
    }
    .media-creator {
      height: 100vh;
    }
  }

  .media-creator .template {
    transition: box-shadow .25s ease-out;
  }

  .media-creator .file-input {
    display: none;
  }
  .media-creator .upload-label {
    display: inline-block;
  }

  .media-creator .preview .content {
    position: absolute;
    /* color: white; */
    font-weight: 700;
  }
  .media-creator .preview .content.text-caption {
    letter-spacing: 0 !important;
  }
  
  .media-creator .preview .user_photo, .media-creator .preview .recipient_photo {
    top: 2px;
    width: 38px;
    height: 38px;
    background-size: cover;
    background-position: center center;
  }
  .media-creator .preview .user_photo {
    left: 2px;
  }
  .media-creator .preview.template-02 .user_photo {
    left: auto;
    right: calc(50% + 6px);
  }
  .media-creator .preview.template-03 .user_photo {
    top: -2px;
  }
  .media-creator .preview .recipient_photo {
    right: 2px;
  }
  .media-creator .preview.template-02 .recipient_photo {
    left: calc(50% + 6px);
  }
  .media-creator .preview.template-03 .recipient_photo {
    top: auto;
    bottom: -2px;
    left: 4px;
  }
  .media-creator .preview .user_name {
    top: 14px;
    left: 64px;
  }
  .media-creator .preview.template-02 .user_name {
    right: calc(50% + 56px);
  }
  .media-creator .preview.template-03 .user_name {
    left: 54px;
  }
  .media-creator .preview em {
    font-size: 9px;
    font-style: normal;
  }
  .media-creator .preview .recipient_name {
    top: 14px;
    left: calc(50% + 38px);
  }
  .media-creator .preview.template-02 .recipient_name {
    left: calc(50% + 56px);
  }
  .media-creator .preview.template-03 .recipient_name {
    top: 62px;
    left: 54px;
  }
  .media-creator .preview .message {
    top: 32px;
    left: 24px;
    right: 24px;
    bottom: 16px;
    letter-spacing: .5px !important;
    line-height: 1.125rem !important;
  }
  .media-creator .preview.template-03 .content.text-caption {
    color: #522A52;
    text-align: left;
    line-height: .75rem;
  }
  .media-creator .preview.template-02 .content.message {
    top: 40px;
    color: #3D4B8F;
  }
  .media-creator .preview.template-03 .content.message {
    top: 16px;
    left: 118px;
    right: 16px;
    bottom: 16px;
    text-align: left;
  }
  .media-creator .preview .ballon {
    position: absolute;
    top: 4px;
    left: calc(50%);
    transform: translateX(-50%);
    width: 36px;
    z-index: 999;
  }
  .media-creator .preview.template-03 .ballon {
    top: 50%;
    transform: translateY(-50%);
    left: 7px;
    margin-top: 2px;
  }

  .recaptcha-text {
    line-height: 1.2;
  }

  .v-snack {
    display: none !important;
  }


</style>

<script>

  // Icons
  import {
    mdiChevronRight,
    mdiChevronLeft,
    mdiCamera,
    mdiClose
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { mask } from 'vue-the-mask'
  import axios from 'axios'
  import ImageUploader from 'vue-image-upload-resize'
  import Vue2Img from 'vue-2-img'
  import 'vue-2-img/dist/vue-2-img.css'

  const geofences = () => import('@/assets/data/rj-rj-bairro-zona.json');

  export default {
    name: 'MediaCreator',
    metaInfo: {
      title: 'Enxame de Amor'
    },

    components: {
      ImageUploader
    },

    data: () => ({
      icons: {
        prev: mdiChevronLeft,
        next: mdiChevronRight,
        photo: mdiCamera,
        mdiClose,
      },
      url: '/campanha/enxamedeamor/templates',
      step: 2,
      steps: [
        {
          title: 'Escolha a arte',
          text: 'Selecione uma das artes abaixo para criar sua mensagem:',
          fields: ['template'],
          complete: false,
        },
        {
          title: 'Informações',
          text: 'Insira seu nome e foto. Faça o mesmo para quem você deseja mandar mensagem.',
          fields: ['user_name', 'user_photo', 'recipient_name', 'recipient_photo'],
          complete: false,
        },
        {
          title: 'Mensagem',
          text: 'Digite sua mensagem:',
          fields: ['message'],
          complete: false,
        },
        {
          title: 'Bairro',
          text: 'Escolha o bairro onde deve passar a mensagem:',
          fields: ['geofence'],
          complete: false,
        },
        {
          title: 'Confirmação',
          text: 'Revise sua mensagem e confirme seus dados para enviar a mensagem.',
          fields: ['fullname', 'email', 'policy'],
          complete: false,
        },
        {
          title: 'Mensagem enviada :D',
          text: 'Recebemos sua mensagem! Avisaremos por e-mail quando entrar no ar.',
          fields: [],
          complete: true,
        },
      ],
      fields: {
        template: {
          value: 0,
          loading: false,
          disabled: false,
        },
        user_name: {
          value: null,
          loading: false,
          disabled: false,
          max: 10,
          hint: 'Deve conter no máximo 8 letras.',
          rules: [v => v!=null || 'Digite seu nome ou apelido. Deve conter no máximo 8 letras.'],
        },
        user_photo: {
          value: null,
          loading: false,
          disabled: false,
          rules: [v => v!=null || 'Envie sua foto.'],
        },
        recipient_name: {
          value: null,
          loading: false,
          disabled: false,
          max: 10,
          hint: 'Deve conter no máximo 8 letras.',
          rules: [v => v!=null || 'Digite seu nome ou apelido. Deve conter no máximo 8 letras.'],
        },
        recipient_photo: {
          value: null,
          loading: false,
          disabled: false,
          rules: [v => v!=null || 'Envie a foto dx destinatárix'],
        },
        message: {
          value: null,
          loading: false,
          disabled: false,
          max: 60,
          hint: 'Deve conter no máximo 60 caracteres.',
          rules: [v => v!=null || 'Deve ter no máximo 60 caracteres.'],
        },
        geofence: {
          value: null,
          items: [],
          loading: false,
          disabled: false,
          rules: [v => v!=null || 'Selecione o bairro.'],
        },
        fullname: {
          value: null,
          loading: false,
          disabled: false,
          rules: [v => v!=null || 'Digite seu nome completo.'],
        },
        email: {
          value: null,
          loading: false,
          disabled: false,
          rules: [v => v!=null || 'Digite seu email.'],
        },
        policy: {
          value: false,
          loading: false,
          disabled: false,
          rules: [v => v==true || 'Para participar é obrigatório concordar com os termos.'],
        },
        subscription: {
          value: true,
          loading: false,
          disabled: false,
        },
      },
      templates: [
        {
          id: '01',
          cover: '/pride-01-cover.jpeg',
          frames: [
            {
              order: 1,
              url: '/pride-01-b.jpeg',
              image: null,
              duration: 4000,
              dynamic: true,
            },
            {
              order: 0,
              url: '/pride-01-a.jpeg',
              image: null,
              duration: 1000,
              dynamic: false,
            },
            {
              order: 2,
              url: '/pride-01-c.jpeg',
              image: null,
              duration: 1000,
              dynamic: false,
            },
            {
              order: 3,
              url: '/pride-01-d.jpeg',
              image: null,
              duration: 1000,
              dynamic: false,
            },
          ]
        },
        // {
        //   id: '02',
        //   cover: '/maes-02-cover.jpg',
        //   frames: [
        //     {
        //       order: 0,
        //       url: '/maes-02-a.jpg',
        //       image: null,
        //       duration: 4000,
        //       dynamic: true,
        //     },
        //     {
        //       order: 1,
        //       url: '/maes-02-b.jpg',
        //       image: null,
        //       duration: 2000,
        //       dynamic: false,
        //     },
        //     {
        //       order: 2,
        //       url: '/maes-02-c.jpg',
        //       image: null,
        //       duration: 2000,
        //       dynamic: false,
        //     },
        //   ]
        // },
        // {
        //   id: '03',
        //   cover: '/maes-03-cover.jpg',
        //   frames: [
        //     {
        //       order: 0,
        //       url: '/maes-03-a.jpg',
        //       image: null,
        //       duration: 4000,
        //       dynamic: true,
        //     },
        //     {
        //       order: 1,
        //       url: '/maes-03-b.jpg',
        //       image: null,
        //       duration: 2000,
        //       dynamic: false,
        //     },
        //     {
        //       order: 2,
        //       url: '/maes-03-c.jpg',
        //       image: null,
        //       duration: 2000,
        //       dynamic: false,
        //     },
        //   ]
        // },
      ],
      render: {
        options: {
          target: '.media-creator .preview',
          // captureHiddenClass: 'vti__hidden',
          // captureShowClass: 'vti__show',
          // captureActiveClass: 'vti__active',
          fileName: 'template-frame',
          fileType: 'jpg',
          returnAction: 'base64', // blob, base64, canvas, clipboard, newWindow
          callback: (img) => { 
            console.log(img);
            return img 
          } // modifies what image is returned
        },
        border: true,
        media: null,
        auth: '62636664306539303430623866313033623965306633366264383831393033653630636264623833333833343531666235343566346234336362303561303761'
      },
      loading: false,
    }),

    watch: {
      step: { 
        immediate: true,
        handler () {
          this.steps[this.step-1].complete = this.validateStep();
        }
      },
      fields: {
        deep: true,
        handler () {
          this.steps[this.step-1].complete = this.validateStep();
        }
      },
      'fields.template.value': {
        immediate: true,
        handler (t) {
          if (t!=null) this.parseFrames(t);
        }
      }
    },

    methods: {
      ...services,

      async recaptcha() {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('login')

        this.sendMedia(token);
      },

      async sendMedia (reCaptcha) {
        this.loading = true;
        this.render.media = await vue2img().image(this.render.options);
        this.render.border = true;

        const user_email = this.fields.email.value;
        const user_name = this.fields.user_name.value;
        const recipient_name = this.fields.recipient_name.value;
        const geofence = this.fields.geofence.value.id;
        const images = _.map(this.templates[this.fields.template.value].frames, f => {
          if (f.dynamic) f.image = this.render.media;
          f.image = f.image.replace('data:image/png;base64,', '');
          f.image = f.image.replace('data:image/jpeg;base64,', '');
          return f;
        });
        const token = this.render.auth;

        this.$api({
          method: 'post',
          url: '/go/campaign/ads/dynamic',
          headers: {
            Authorization: token,
          },
          data: {
            email: 'dsp_mobees',
            user_email,
            user_name,
            recipient_name,
            id_local: parseInt(geofence),
            reCaptcha,
            images
          }
        })
        .then(response => {
          if (response.status==200) {
            console.log(response);
            this.step += 1;
          }
        })
        .catch(error => {
          this.handleError(error);
        })
        .finally(() => {
          this.loading = false;
        });
      },

      async parseFrames (t) {
        for await (let f of this.templates[t].frames) {
          const frame = f;
          if (frame.image==null) frame.image = await this.toBase64(this.url+frame.url); 
        };
      },

      async toBase64 (url) {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      },

      validateStep () {
        const fields = this.steps[this.step-1].fields;
        return _.every(fields, f => _.has(this.fields[f], 'rules') ? _.every(this.fields[f].rules, r => r(this.fields[f].value)==true) : true);
      },

      uploadPhoto (target) {
        console.log('uploadPhoto', target);
        this.fields[target].loading = true;
      },
      setPhoto (output) {
        console.log('setPhoto');
        const target = this.fields.user_photo.loading ? 'user_photo' : 'recipient_photo';
        this.fields[target].value = output.dataUrl;
        this.fields[target].loading = false;
        console.log('Info', output.info)
        // console.log('Exif', output.exif)
      },

      selectTemplate (i) {
        setTimeout(($) => {
          $.fields.template.value = i;
          $.nextStep();
        }, 250, this);
      },

      nextStep (b) {
        if (b&&this.step==5) {
          this.render.border = false;
          setTimeout(this.recaptcha, 250);
          return false;
        }else if (b&&this.step==6) {
          _.each(this.fields, field => {
            field.value = null;
          });
          // _.each(this.templates, template => {
          //   _.each(template.frames, frame => {
          //     frame.image = null;
          //   });
          // });
          this.step = 0;
        }
        this.step = _.isNil(b)||b ? this.step + 1 : this.step - 1;
      }

    },

    mounted () {
      geofences().then((data) => {
        this.fields.geofence.items = _.sortBy(_.flatten(_.map({...data.geofences[0]}, z => {
          return z.geofences;
        })), ['title']);
        // console.log(this.fields.geofence.items);
      });
    },

    directives: {
      mask,
    },

  }
</script>
